import { Close, Minimize, OpenInFull } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import useEmailActions from "hook/useEmailActions";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSocket } from "SocketContext";
import "./composeDialog.css";

export default function ComposeDialog() {
  const [isSending, setIsSending] = useState(false);

  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");

  const [contend, setContend] = useState("");

  const { sendEmail } = useEmailActions();
  const { isSendBoxOpen, setSendBoxOpen, defaultEmail } = useSocket();
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isSendBoxOpen}
      >
        <Box
          sx={{
            width: "550px",
            backgroundColor: "#ffffff",
            height: "420px",
            boxShadow: 8,
            borderRadius: "12px",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#f2f6fc",
              display: "flex",
              justifyItems: "center",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <Typography sx={{ margin: "5px" }}>New Message</Typography>

            <Box
              sx={{
                display: "flex",
                justifyItems: "center",
              }}
            >
              <IconButton>
                <Minimize fontSize="small" />
              </IconButton>
              <IconButton>
                <OpenInFull fontSize="small" />
              </IconButton>

              <IconButton>
                <Close
                  fontSize="small"
                  onClick={() => {
                    setSendBoxOpen(false);
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingX: "20px",
            }}
          >
            <TextField
              id="standard-basic"
              label="To"
              size="small"
              sx={{ fontSize: "12px" }}
              value={to}
              variant="standard"
              onChange={(event: any) => {
                setTo(event?.target.value);
              }}
            />
            <TextField
              id="standard-basic"
              label="Subject"
              size="small"
              sx={{ fontSize: "12px" }}
              value={subject}
              variant="standard"
              onChange={(event: any) => {
                setSubject(event?.target.value);
              }}
            />
            <Box sx={{ display: "flex", margin: "10px" }}>
              <Button
                size="small"
                sx={{ marginLeft: "auto" }}
                variant="contained"
                onClick={async () => {
                  setIsSending(true);
                  await sendEmail({
                    user: defaultEmail.user,
                    from: defaultEmail.user,
                    to,
                    contend,
                    subject,
                    originalMessageId: "",
                  });
                  setIsSending(false);
                  setSendBoxOpen(false);
                }}
              >
                {isSending ? <CircularProgress size={12} /> : "Send"}
              </Button>
            </Box>

            <ReactQuill
              // modules={{ toolbar: false }}
              theme="snow"
              value={contend}
              onChange={setContend}
            />
          </Box>
        </Box>
      </Snackbar>
    </>
  );
}
