const AppRoutes = {
  InboxPage: "/inbox-page",
  EmailDetails: "/email-detail-page",
  LoginPage: "/",
  StarredPage: "/starred-page",
  SnoozedPage: "/snoozed-page",
  DraftPage: "/draft-page",
  ImportantPage: "/important-page",
  ScheduledPage: "/scheduled-page",
  AllMailPage: "/all-mail-page",
  SpamPage: "/spam-page",
  TrashPage: "/trash-page",
  SentPage: "/sent-page",
  FolderPage: "/folder-page",
  EmailPage: "/email-page",
};

export default AppRoutes;
