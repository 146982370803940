import { createContext, useContext, useEffect, useMemo, useState } from "react";

const SocketContext = createContext<any>(null);

export const useSocket = () => {
  return useContext(SocketContext);
};

export function SocketProvider({ children }: any) {
  const socket = null;
  const [isSendBoxOpen, setSendBoxOpen] = useState(false);
  const [inboxCount, setInboxCount] = useState<any>(() => {
    const savedEmails = localStorage.getItem("inboxCount");
    return savedEmails ? JSON.parse(savedEmails) : 0;
  });

  const [emails, setEmails] = useState<any>(() => {
    const savedEmails = localStorage.getItem("emails");
    return savedEmails ? JSON.parse(savedEmails) : [];
  });

  const [defaultEmail, setDefaultEmail] = useState<any>(() => {
    const savedDefaultEmail = localStorage.getItem("defaultEmail");
    return savedDefaultEmail ? JSON.parse(savedDefaultEmail) : null;
  });

  useEffect(() => {
    localStorage.setItem("emails", JSON.stringify(emails));
  }, [emails]);

  useEffect(() => {
    localStorage.setItem("inboxCount", JSON.stringify(inboxCount));
  }, [inboxCount]);

  useEffect(() => {
    localStorage.setItem("defaultEmail", JSON.stringify(defaultEmail));
  }, [defaultEmail]);

  const contextValue = useMemo(
    () => ({
      socket,
      emails,
      setSendBoxOpen,
      setDefaultEmail,
      defaultEmail,
      setEmails,
      inboxCount,
      setInboxCount,
      isSendBoxOpen,
    }),
    [
      socket,
      emails,
      setSendBoxOpen,
      setDefaultEmail,
      defaultEmail,
      setEmails,
      inboxCount,
      setInboxCount,
      isSendBoxOpen,
    ],
  );

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
}
