// const origin = process.env.REACT_APP_BASE_API_URL_DEV;
const BASE_URL = `https://www.cutcrm.com:90/api`;
const endPoints = {
  common: "userlogin",
  getAll: "get-all",
  getById: "getbyid",
  getbythreadid: "getbythreadid",
};

const API_URLS = {
  getAll: {
    index: `${BASE_URL}/inbox/${endPoints.getAll}`,
    starred: `${BASE_URL}/starred/${endPoints.getAll}`,
    sent: `${BASE_URL}/sent/${endPoints.getAll}`,
    draft: `${BASE_URL}/draft/${endPoints.getAll}`,
    important: `${BASE_URL}/important/${endPoints.getAll}`,
    all: `${BASE_URL}/all/${endPoints.getAll}`,
    spam: `${BASE_URL}/spam/${endPoints.getAll}`,
    trash: `${BASE_URL}/trash/${endPoints.getAll}`,
    folder: `${BASE_URL}/folder/${endPoints.getAll}`,
    folderName: `${BASE_URL}/folder-name/${endPoints.getAll}`,
    thread: `${BASE_URL}/thread/${endPoints.getAll}`,
    emails: `${BASE_URL}/emails/${endPoints.getAll}`,
  },
  action: {
    addRemoveFlag: `${BASE_URL}/action/flag`,
    addFolder: `${BASE_URL}/action/create-folder`,
    removeFolder: `${BASE_URL}/action/delete-folder`,
    moveFolder: `${BASE_URL}/action/move-folder`,
    sendEmail: `${BASE_URL}/action/sent-email`,
  },
  common: {
    getEmailList: `${BASE_URL}/common/get-all-emails`,
    updateData: `${BASE_URL}/common/update-data`,
  },
};

export default API_URLS;
